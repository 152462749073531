import { Injectable } from '@angular/core';
import { CacheService, DtoDetailService, SettingService } from "@ngx-dms/common-services";
import { map, Observable, of} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { IRepairOrderDetailDto } from "../interfaces/dto/repair-order-detail-dto.interface";
import { IRepairOrderDetail } from "../interfaces/repair-order-detail.interface";
import { CreateWarrantyClaimCommandHandler } from "../commands/create-warranty-claim.command";
import { IResponseDTO } from "../interfaces/dto/response-dto.interface";

@Injectable({
  providedIn: 'root'
})
export class RepairOrderDetailService extends DtoDetailService<IRepairOrderDetailDto, IRepairOrderDetail> {

  constructor(protected cache: CacheService, private setting: SettingService, private http: HttpClient) {
    super();

    this.registerCommand('create-warranty-claim', new CreateWarrantyClaimCommandHandler(http, setting));
  }

  protected getCacheKey(id: string) {
    return `repair-order.${id}`;
  }

  getRepairOrder(id: string, authUuid: string): Observable<IRepairOrderDetail> {
    let params = {
        repairOrderUuid: id,
        authorizationUuid: authUuid
    };
    return this.http.get<IResponseDTO<IRepairOrderDetailDto>>(this.setting.routeUrl(`v1/repair_order/warranty_claim`), { params: params })
      .pipe(
        map(result => result.data),
        map<IRepairOrderDetailDto, IRepairOrderDetail>(
          repairOrder => <IRepairOrderDetail>{
            order: {
              id: repairOrder.id,
              orderNumber: repairOrder.dmsId,
              openingDate: repairOrder.openingDateTime,
              closingDate: repairOrder.closingDateTime,
              odometer: repairOrder.repairOrderVehicle.odometerReadingIn,
              jobs: repairOrder.jobs,
              advisor: repairOrder.serviceAdvisor.fullName
            },
            customer: {
              name: repairOrder.soldToCustomer.fullName,
              addresses: repairOrder.soldToCustomer.addresses,
              emails: repairOrder.soldToCustomer.emails,
              phone: repairOrder.soldToCustomer.telephoneNumbers
            },
            vehicle: {
              manufacturerIdCode: repairOrder.repairOrderVehicle.vehicle.vehicleMake.manufacturer.idCode,
              brand: repairOrder.repairOrderVehicle.vehicle.vehicleMake.description,
              VIN: repairOrder.repairOrderVehicle.vehicle.vin,
              model: repairOrder.repairOrderVehicle.vehicle.modelDescription,
              exteriorColor: repairOrder.repairOrderVehicle.vehicle.exteriorColor,
              interiorColor: repairOrder.repairOrderVehicle.vehicle.interiorColor,
              deliveredDate: repairOrder.repairOrderVehicle.vehicle.dateReceived,
              warrantyExpirationDate: undefined
            },
            authoritativeSource: repairOrder.authoritativeSource,
          }),
      )
   }


  public fetchData(id: string): void {
  }

  protected getRequestObservable(id: string): Observable<IRepairOrderDetailDto> {
  //   return this.http.get<IDataResult<IRepairOrderDetailDto>>(this.setting.routeUrl(`v1/repair_order/warranty_claim`), {
  //       params: {
  //         repairOrderUuid: id
  //       }
  //     })
  //     .pipe(
  //       map(result => result.data),
  //       tap(repairOrder => {
  //         // we don't want to cache anything for this
  //         this.cache.set(this.getCacheKey(id), repairOrder);
  //       })
  //     );
      return of({} as IRepairOrderDetailDto);
  }
}
