import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Injectable({ providedIn: 'root' })
export class CustomTitleResolver implements Resolve<string> {
  constructor(
    private _translateService: TranslateService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<string> {
    if (!route.data["titleKey"]) {
      return Promise.resolve('API Broker');
    }

    return Promise.resolve(
      "API Broker - " + this._translateService.instant(route.data["titleKey"])
    );
  }
}

