import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { RouterModule }                                          from "@angular/router";
import { AuthService }                                           from "../../auth/auth.service";
import { TranslateService }                                      from "@ngx-translate/core";
import { IsoCountryService }                                     from "../../services/iso-country.service";
import {ClaimPage} from "../../pages/claim/claim.page";

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, NgOptimizedImage],
  templateUrl: './claim.layout.html',
  styleUrls: ['./claim.layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClaimLayout {
  dmsName = "";

  constructor(private authService: AuthService,
              public translate: TranslateService,
              private changeDetectorRef: ChangeDetectorRef,
              private isoCountryService: IsoCountryService,
              private claimPage: ClaimPage) {
    this.isoCountryService.onLoad.subscribe(_ => this.changeDetectorRef.markForCheck());
    this.claimPage.dmsNameLoaded.subscribe((response) => {
      this.dmsName=response.dmsName;
    });
  }

  logout() {
    this.authService.logout();
  }

  changeLanguage() {
    let lang = 'fr_CA';
    if (this.translate.currentLang === 'fr_CA') {
      lang = 'en_CA';
    }

    this.translate.use(lang).subscribe(_ => this.changeDetectorRef.markForCheck());
  }
}
