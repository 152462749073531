<div class="toast-header">
  <strong *ngIf="title" class="me-auto">{{ title }}</strong>
  <small *ngIf="diff">{{ timeAgo | async }}</small>
  <button *ngIf="options.closeButton" type="button" class="btn-close white" [attr.aria-label]="'ACCESSIBILITY.GENERAL.CLOSE_WINDOW'" (click)="dismiss()"></button>
</div>
<div class="toast-body">
  {{ message }}
</div>
<div>
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>