import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy, Output
} from '@angular/core';
import {CommonModule, formatNumber} from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import { DmsButtonComponent, DmsCardModule, DmsPageTitleModule, DmsSectionModule } from "@ngx-dms/ui";
import { NgSelectModule } from "@ng-select/ng-select";
import { map, Observable, Subscription, zip } from "rxjs";
import { IRepairOrderDetail } from "../../interfaces/repair-order-detail.interface";
import { RepairOrderDetailService } from "../../services/repair-order-detail.service";
import { AddressComponent } from "../../components/address/address/address.component";
import { TabDirective, TabsModule } from "ngx-bootstrap/tabs";
import { AgGridModule } from "ag-grid-angular";
import {ColDef, GridOptions, GridReadyEvent, ValueFormatterParams} from "ag-grid-community";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { IJobDetail } from "../../interfaces/job-detail.interface";
import { ISystemCode, SystemCodeDetailService } from "../../services/system-code-detail.service";
import { ICreateWarrantyClaimCommand } from "../../commands/create-warranty-claim.command";
import {NgxMaskModule} from "ngx-mask"
import { ToastrService } from 'ngx-toastr';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    DmsSectionModule,
    DmsCardModule,
    DmsPageTitleModule,
    DmsButtonComponent,
    NgSelectModule,
    AddressComponent,
    TabsModule,
    AgGridModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule
  ],
  templateUrl: './claim.page.html',
  styleUrls: ['./claim.page.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})

export class ClaimPage implements OnDestroy {

  @Output() dmsNameLoaded = new EventEmitter<{dmsName: string}>();
  // private claimSupplier?: string;
  private supportedManufacturer = ['brp'];
  private repairOrderUuid?: string;
  private authUuid?: string;
  public data$?: Observable<IRepairOrderDetail>;
  public currentTab: string = 'parts';
  private dataSub?: Subscription;
  private sysCodeSub?: Subscription;
  public repairOrderDetails?: IRepairOrderDetail;
  public selectedJob?: IJobDetail;
  public partsColumnDefs: ColDef[] = [
    {
      field: 'part.sku',
      headerName: this.translate.instant('PART.SKU'),
    },
    {
      field: 'part.description',
      headerName: this.translate.instant('PART.DESCRIPTION'),
    },
    {
      field: 'quantitySold',
      headerName: this.translate.instant('PART.QUANTITY_SOLD'),
      type: 'rightAligned'
    },
    {
      field: 'amountPriceSold',
      headerName: this.translate.instant('PART.AMOUNT_PRICE_SOLD'),
      type: 'rightAligned',
      valueFormatter: currencyFormatter
    },
    {
      field: 'chargeType',
      headerName: this.translate.instant('PART.CHARGE_TO'),
      valueFormatter: (params) => {
        if (params.value === 'WARRANTY')
          return this.translate.instant('PART.WARRANTY');

        if (params.value === 'CUSTOMER')
          return this.translate.instant('PART.CUSTOMER');

        return;
      }
    }
  ];
  public labourColumnDefs: ColDef[] = [
    {
      field: 'description',
      headerName: this.translate.instant('LABOUR.DESCRIPTION'),
    },
    {
      field: 'hoursWorked',
      headerName: this.translate.instant('LABOUR.HOURS_WORKED'),
      type: 'rightAligned',
      valueFormatter: hourFormatter
    },
    {
      field: 'hoursCharged',
      headerName: this.translate.instant('LABOUR.HOURS_CHARGED'),
      type: 'rightAligned',
      valueFormatter: hourFormatter
    },
    {
      field: 'chargeType',
      headerName: this.translate.instant('LABOUR.CHARGE_TO'),
      valueFormatter: (params) => {
        if (params.value === 'WARRANTY')
          return this.translate.instant('LABOUR.WARRANTY');

        if (params.value === 'CUSTOMER')
          return this.translate.instant('LABOUR.CUSTOMER');

        return;
      }
    },
    {
      field: 'employee.fullName',
    }
  ];

  public defaultColDef: ColDef = {
    resizable: true,
    autoHeight: true,
    sortable: true,
    filter: true
  };

  private currentClaimSubmitted: boolean[] = [];

  private labourGridEvent?: GridReadyEvent;
  private partGridEvent?: GridReadyEvent;
  public systemCodes$?: Observable<ISystemCode[]>;

  public form = new FormGroup({
    causal_part: new FormControl<number|undefined>(undefined, { validators: [Validators.required], nonNullable: true }),
    system_code: new FormControl<string|undefined>(undefined, { validators: [Validators.required], nonNullable: true }),
    symptom_description: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    remedy_description: new FormControl<string>('', { validators: [], nonNullable: true }),
    defect_description: new FormControl<string>('', { validators: [], nonNullable: true }),
  });

  get jobWarrantyStatus() {
    if (this.selectedJob?.warranties.length === 0) return 'UNKNOWN';
    return this.selectedJob?.warranties[0].status;
  }

  get jobWarrantyNumber() {
    if (this.selectedJob?.warranties.length === 0) return '';
    if (this.selectedJob?.warranties[0].warrantyNumber === undefined){
      return '';
    } else {
      return this.selectedJob?.warranties[0].warrantyNumber;
    }
  }

  @HostListener('window:resize')
  onResize() {
    setTimeout(() => {
      if (this.currentTab === 'parts')
        this.partGridEvent?.api.sizeColumnsToFit();
      else if (this.currentTab === 'labour')
        this.labourGridEvent?.api.sizeColumnsToFit();
    });
  }

  constructor(activatedRoute: ActivatedRoute,
    public repairOrderDetailService: RepairOrderDetailService,
    public translate: TranslateService,
    private changeRef: ChangeDetectorRef,
    private systemCodeService: SystemCodeDetailService,
    private toastr: ToastrService) {

    activatedRoute.queryParams.subscribe(queryParams => {
      this.repairOrderUuid = queryParams['repairOrderUuid'];
      this.authUuid = queryParams['authUuid'];
      if (!this.repairOrderUuid || !this.authUuid) {
        this.toastr.error(this.translate.instant('CLAIM.MISSING_PARAMETERS'));
        return;
      }
      this.loadData();
    });
  }

  loadData() {

    if (this.repairOrderUuid) {

      this.repairOrderDetailService.getRepairOrder(this.repairOrderUuid!, this.authUuid!).pipe().subscribe({
        next: data =>  {
          this.repairOrderDetails = data;
          if (this.selectedJob) {
            this.selectedJob = data.order.jobs.find(job => job.sequenceId == this.selectedJob?.sequenceId);
          }

          for (let job of data.order.jobs) {
            job.title = job.sequenceId + " - " + job.title;
            for (let part of job.parts) {
              part.part.label = part.part.sku + " - " + part.part.description;
            }
            this.currentClaimSubmitted[job.sequenceId] = false;
          }

          this.dmsNameLoaded.emit({dmsName: data.authoritativeSource.toString()});

          this.changeRef.markForCheck();

        },
        error: err => {
          if (err.status == 401) {
            this.toastr.error(this.translate.instant("GLOBAL.UNAUTHORIZED"));
          } else {
            this.toastr.error(this.translate.instant("GLOBAL.ERROR_MSG"));
          }
          console.error("Error details:" , err);
        }
      });

      this.systemCodes$ = this.systemCodeService.getManufacturerInfo(this.repairOrderUuid, this.authUuid!);

    }
  }

  isManufacturerSupported() {
    if (this.repairOrderDetails && this.repairOrderDetails.vehicle.manufacturerIdCode) {
      return this.supportedManufacturer.includes(this.repairOrderDetails.vehicle.manufacturerIdCode);
    } else {
      return true;
    }
  }

  refresh() {
    this.loadData();
  }

  onTabChange(event: TabDirective) {
    if (event.id === undefined)
      return;

    this.currentTab = event.id;
    this.onResize();
  }

  get partGridOptions(): GridOptions {
    return {
      onGridReady: (event) => {
        this.partGridEvent = event;
        event.api.sizeColumnsToFit();
      }
    }
  }

  get labourGridOptions(): GridOptions {
    return {
      onGridReady: (event) => {
        this.labourGridEvent = event;
        event.api.sizeColumnsToFit();
      }
    }
  }

  ngOnDestroy(): void {
    this.dataSub?.unsubscribe();
    this.sysCodeSub?.unsubscribe();
  }

  onSubmit() {
    this.form.markAllAsTouched();

    this.changeRef.markForCheck();

    if (this.form.invalid || this.repairOrderUuid === undefined || this.selectedJob === undefined) return;

    if (!this.currentClaimSubmitted[this.selectedJob.sequenceId]) {
      this.currentClaimSubmitted[this.selectedJob.sequenceId] = true;

      let command: ICreateWarrantyClaimCommand = {
        jobId: this.selectedJob.uuid,
        authorizationUuid: this.authUuid as string,
        causalPartId: this.form.value.causal_part as number, // if form is valid we know this is a number
        systemCode: this.form.value.system_code as string,
        symptomDescription: this.form.value.symptom_description as string, // if form is valid we know this is a string
        defectDescription: this.form.value.defect_description,
        remedyDescription: this.form.value.remedy_description,
      };

      this.repairOrderDetailService.execute('create-warranty-claim', command)
        .subscribe(data => {
            this.loadData();
            this.toastr.success(this.translate.instant('CLAIM.CLAIM_SUBMITTED'));
          }, error => {
            console.log("Error payload:", error);
            this.toastr.error(this.translate.instant('CLAIM.CLAIM_ERROR') + error.error.message, "Error");
          }
        );
    } else {
      return;
    }
  }

  hasError(control: FormControl<any>) {
    return control.invalid && (control.dirty || control.touched);
  }

  errorMessage(control: FormControl<any>) {
    if (control.errors === null) return undefined;

    let observables = Object.keys(control.errors)
      .map(error => {
        return this.translate.get(`FORM.ERRORS.${error.toUpperCase()}`);
      });

    return zip(observables)
      .pipe(map((results : string[]) => results.join(`<br />`)));
  }

  jobSelected(selected: IJobDetail) {

    this.selectedJob = selected;

    this.form.get('causal_part')?.reset();
    this.form.get('system_code')?.reset();

    if (this.jobWarrantyStatus + '' == 'SUBMITTED' || this.jobWarrantyStatus + '' == 'RECEIVED') {
      // this.form.get('causal_part').
      this.form.get('causal_part')?.setValue(selected.warranties[0].warrantyClaim.causalPartId);
      this.form.get('system_code')?.setValue(selected.warranties[0].warrantyClaim.systemCode);
      this.form.get('symptom_description')?.setValue(selected.warranties[0].warrantyClaim.symptomDescription);
      this.form.get('remedy_description')?.setValue(selected.warranties[0].warrantyClaim.remedyDescription);
      this.form.get('defect_description')?.setValue(selected.warranties[0].warrantyClaim.defectDescription);
    } else {
      this.form.get('symptom_description')?.setValue(selected.description);
      this.form.get('remedy_description')?.setValue(selected.closingNotes);
      this.form.get('defect_description')?.setValue(selected.technicianNotes);
    }

    selected.description = selected.description.replace(/\r/g,"")
        .replace(/\n/g, "<br />");
    selected.technicianNotes = selected.technicianNotes.replace(/\r/g,"")
        .replace(/\n/g, "<br />");
    selected.closingNotes = selected.closingNotes.replace(/\r/g,"")
        .replace(/\n/g, "<br />");
  }

  // protected readonly Object = Object;
}

function currencyFormatter(params: ValueFormatterParams) {
  return '$ ' + formatNumber(params.value, 'en-CA', "1.2");
}

function hourFormatter(params: ValueFormatterParams) {
  return formatNumber(params.value, 'en-CA', "1.2") + " hrs";
}
