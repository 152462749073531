<header class="app-header d-flex align-items-center">
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="col-6 col-md-auto">
        <h1 class="row">
          <div class="col-12 col-md-auto align-items-center">
            <svg fill="none" height="28" viewBox="0 0 93 28" width="93" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.65159 7.23564H6.79274V8.81311H0.609375V0.925781H2.65159V7.23564Z" fill="black" />
              <path
                d="M14.2835 8.70043C12.5968 8.85066 11.1975 8.92578 10.0856 8.92578C9.34439 8.92578 8.7998 8.75301 8.45187 8.40747C8.10393 8.06193 7.92997 7.52109 7.92997 6.78494V3.1793C7.92997 2.39057 8.10771 1.81968 8.46321 1.46663C8.82627 1.10606 9.4049 0.925781 10.1991 0.925781H14.2835V2.50325H10.6529C10.1991 2.50325 9.97218 2.7286 9.97218 3.1793V6.78494C9.97218 6.95771 10.0213 7.09667 10.1197 7.20184C10.2256 7.29949 10.3579 7.34832 10.5168 7.34832C10.6756 7.34832 10.8496 7.34832 11.0387 7.34832C11.2278 7.34081 11.4244 7.33329 11.6286 7.32578C11.8329 7.31827 12.0371 7.31076 12.2413 7.30325C12.4531 7.29573 12.7216 7.28071 13.0468 7.25818C13.3797 7.23564 13.7919 7.20935 14.2835 7.1793V8.70043Z"
                fill="black" />
              <path
                d="M22.5672 4.41874L20.9788 7.23564H19.3904L17.802 4.41874V8.81311H15.7598V0.925781H17.9155L20.1846 5.0948L22.4537 0.925781H24.6094V8.81311H22.5672V4.41874Z"
                fill="black" />
              <path d="M7.74439 26.8834H4.45131V16.2037H0.609375V13.5337H11.5863V16.2037H7.74439V26.8834Z"
                fill="black" />
              <path
                d="M22.0166 17.1572C22.0166 16.3944 21.6507 16.0129 20.9189 16.0129H17.8087C17.0769 16.0129 16.711 16.3944 16.711 17.1572V23.2599C16.711 24.0227 17.0769 24.4042 17.8087 24.4042H20.9189C21.6507 24.4042 22.0166 24.0227 22.0166 23.2599V17.1572ZM25.3096 23.2599C25.3096 24.5949 25.0169 25.5675 24.4315 26.1778C23.8583 26.7753 22.9313 27.0741 21.6507 27.0741H17.0769C15.7963 27.0741 14.8633 26.7753 14.2778 26.1778C13.7046 25.5675 13.418 24.5949 13.418 23.2599V17.1572C13.418 15.8222 13.7046 14.856 14.2778 14.2584C14.8633 13.6482 15.7963 13.343 17.0769 13.343H21.6507C22.9313 13.343 23.8583 13.6482 24.4315 14.2584C25.0169 14.856 25.3096 15.8222 25.3096 17.1572V23.2599Z"
                fill="black" />
              <path
                d="M36.2952 17.1572C36.2952 16.3944 35.9293 16.0129 35.1975 16.0129H32.0873C31.3555 16.0129 30.9896 16.3944 30.9896 17.1572V23.2599C30.9896 24.0227 31.3555 24.4042 32.0873 24.4042H35.1975C35.9293 24.4042 36.2952 24.0227 36.2952 23.2599V17.1572ZM39.5883 23.2599C39.5883 24.5949 39.2955 25.5675 38.7101 26.1778C38.1369 26.7753 37.2099 27.0741 35.9293 27.0741H31.3555C30.0749 27.0741 29.1419 26.7753 28.5564 26.1778C27.9832 25.5675 27.6966 24.5949 27.6966 23.2599V17.1572C27.6966 15.8222 27.9832 14.856 28.5564 14.2584C29.1419 13.6482 30.0749 13.343 31.3555 13.343H35.9293C37.2099 13.343 38.1369 13.6482 38.7101 14.2584C39.2955 14.856 39.5883 15.8222 39.5883 17.1572V23.2599Z"
                fill="black" />
              <path d="M45.6341 24.2135H52.3118V26.8834H42.3411V13.5337H45.6341V24.2135Z" fill="black" />
              <path
                d="M54.5115 13.5337H61.8294C63.0247 13.5337 63.9028 13.8198 64.4639 14.3919C65.0249 14.9513 65.3055 15.8095 65.3055 16.9665C65.3055 17.7293 65.1469 18.3523 64.8298 18.8354C64.5127 19.3059 64.1224 19.6364 63.6589 19.8271C64.6468 20.1068 65.3177 20.7934 65.6714 21.8868C65.7933 22.2809 65.8543 22.7386 65.8543 23.2599C65.8543 24.5059 65.5738 25.4213 65.0127 26.0061C64.4517 26.591 63.5735 26.8834 62.3783 26.8834H54.5115V13.5337ZM62.5612 22.6878C62.5612 21.9249 62.1953 21.5435 61.4635 21.5435H57.8045V24.2135H61.4635C62.1953 24.2135 62.5612 23.832 62.5612 23.0692V22.6878ZM62.0124 17.3479C62.0124 16.5851 61.6465 16.2037 60.9147 16.2037H57.8045V18.8736H60.9147C61.6465 18.8736 62.0124 18.4922 62.0124 17.7293V17.3479Z"
                fill="black" />
              <path
                d="M88.8589 13.5337H92.5179L88.4015 20.2086L92.6094 26.8834H88.9504L86.3891 22.6878L83.8278 26.8834H80.1688L84.3767 20.2086L80.2603 13.5337H83.901L86.3891 17.7293L88.8589 13.5337Z"
                fill="black" />
              <path
                d="M79.6066 21.8522L78.5099 21.1878C78.6374 20.5234 78.6374 19.8867 78.5099 19.2223L79.6066 18.5579C79.7341 18.4749 79.7851 18.3088 79.7341 18.1704C79.4536 17.1738 78.969 16.2879 78.3568 15.5405C78.2548 15.4298 78.1018 15.4021 77.9742 15.4851L76.8775 16.1495C76.4184 15.7343 75.9083 15.4021 75.3471 15.1806V13.8242C75.3471 13.6857 75.2451 13.5473 75.0921 13.5196C74.1484 13.2705 73.2047 13.2982 72.312 13.5196C72.1589 13.5473 72.0824 13.6857 72.0824 13.8242V15.1806C71.4958 15.4021 70.9857 15.7343 70.5266 16.1772L69.4298 15.4851C69.3023 15.4021 69.1492 15.4298 69.0472 15.5405C68.4351 16.2879 67.9505 17.1738 67.6699 18.1704C67.6189 18.3088 67.6699 18.4749 67.7974 18.5579L68.8942 19.2223C68.7922 19.8867 68.7922 20.5234 68.8942 21.1878L67.7974 21.8522C67.6699 21.9353 67.6189 22.1014 67.6699 22.2398C67.9505 23.2364 68.4351 24.1222 69.0472 24.8697C69.1492 24.9804 69.3023 25.0081 69.4298 24.925L70.5266 24.2606C70.9857 24.6759 71.4958 25.0081 72.0824 25.2295V26.586C72.0824 26.7244 72.1844 26.8628 72.312 26.9182C73.2557 27.1397 74.1994 27.112 75.0921 26.9182C75.2451 26.8628 75.3471 26.7244 75.3471 26.586V25.2295C75.9083 25.0081 76.4184 24.6759 76.8775 24.2606L77.9742 24.925C78.1018 25.0081 78.2548 24.9804 78.3568 24.8697C78.9945 24.1222 79.4536 23.2364 79.7596 22.2398C79.7851 22.1014 79.7341 21.9353 79.6066 21.8522ZM73.7148 22.4059C72.567 22.4059 71.6743 21.437 71.6743 20.1912C71.6743 18.9732 72.567 17.9766 73.7148 17.9766C74.837 17.9766 75.7552 18.9732 75.7552 20.1912C75.7552 21.437 74.837 22.4059 73.7148 22.4059Z"
                fill="black" />
            </svg>
          </div>
        </h1>
      </div>
      <div class="col-6 col-md-auto">
        <div class="row align-items-center justify-content-end justify-content-md-start ">
          <div class="col-auto">
            <button type="button" role="button" translate>COMPANY.HEADER.SUMMARY</button>
          </div>
          <div class="col-auto">
            <button type="button" role="button" translate>COMPANY.HEADER.PRICE_LISTS</button>
          </div>
          <div class="col-auto">
            <!--  ToDo: Est-ce que ce lien est bien fait? -->
            <button type="button" role="button" class="selected" routerLink="/company" translate>COMPANY.HEADER.CLIENTS</button>
          </div>
          <div class="col-auto">
            <button type="button" role="button" translate>COMPANY.HEADER.SERVICES_API</button>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="row align-items-center justify-content-end justify-content-md-start ">
          <div class="col-auto">
            <button type="button" role="button" (click)="changeLanguage()">{{translate.currentLang == 'fr_CA' ?  ('LANG.en_CA' | translate) : ('LANG.fr_CA' | translate)}}</button>
          </div>
          <div class="col-auto">
            <button class="circle" cdkOverlayOrigin type="default" dmsDropdown [items]="items">
              <img src="/assets/img/avatar.png">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<main class="app-main">
  <router-outlet></router-outlet>
</main>
