import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "./auth.service";
import { HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.canView(route);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.canView(childRoute);
  }

  private canView(route: ActivatedRouteSnapshot): boolean {
    //Check if we are in the claim section
    if (route.url[0]?.path == 'claim') {

      //const claimSupplier = route.queryParamMap.get('claimSupplier'); // what do we do with this?
      const repairOrderUuid = route.queryParamMap.get('repairOrderUuid');
      const authUuid = route.queryParamMap.get('authUuid');

      if (repairOrderUuid === null || repairOrderUuid.trim().length === 0) {
        return false;
      }

      const hasAuthUuidToken = authUuid !== null && authUuid.length > 0;

      // if development allow redirect to log in otherwise we need the authUuid
      // if (false === environment.production) {
      //   console.log('not prod')
      //   const isLoggedIn = this.authService.isLoggedIn;
      //   if (isLoggedIn) return true;

      //   if (false === hasAuthUuidToken) {
      //     let params = { repairOrderUuid: repairOrderUuid};
      //     let redirectUri = window.location.origin + '/' + route.routeConfig?.path;
      //     const searchParams = new HttpParams({ fromObject: params }).toString();
      //     if (searchParams) {
      //       redirectUri = redirectUri + '?' + searchParams;
      //     }

      //     this.authService.login(redirectUri);
      //     return false;
      //   }
      // }

      return hasAuthUuidToken;

    } else if (!this.authService.isLoggedIn) {

      let redirectUri = window.location.origin + '/' + route.routeConfig?.path;
      this.authService.login(redirectUri);

    }
     return true;

  }
}
