

<div class="btn-group">
  <!-- Button -->
  <dms-button [id]="id" [type]="type" [title]="text | async" dmsDropdown [items]="items">

    <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
    <span [class.visually-hidden]="type === 'ellipsis'">{{ text | async }}</span>
  </dms-button>
</div>
