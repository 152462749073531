import { Injectable }       from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { of }               from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private translate: TranslateService) { }

  initialize() {
    this.translate.addLangs(['fr_CA','en_CA','en_US','es_MX']);

    const lang = localStorage.getItem('language');
    if (lang != null && this.translate.langs.includes(lang)) {
      this.translate.use(lang);
      return;
    }

    const culture = this.translate.getBrowserCultureLang();
    if (culture && this.translate.langs.includes(culture)) {
      this.translate.use(culture);
      return;
    }

    // no compatible language in the system, let's use the default language
    this.set(this.translate.defaultLang);
  }

  set(language: string) {
    if (this.translate.langs.includes(language) === false)
      return of(false);

    localStorage.setItem('language', language);
    return this.translate.use(language);
  }

  formatDate(sDate?: string) {
    if (!sDate) {
      return '';
    }
    let date = new Date(sDate);
    const lang = localStorage.getItem('language');
    if (lang == null || lang == 'en_CA') {
      return this.formatMonth(date.getMonth()) + '-' + date.getDate() + '-' + date.getFullYear();
    }
    return date.getFullYear() + '-' + this.formatMonth(date.getMonth()) + '-' + date.getDate();
  }

  private formatMonth(month: number) {
    month++;
    return month < 10 ? '0' + month : month;
  }
}
