import { NgModule }        from '@angular/core';
import { CommonModule }    from '@angular/common';
import { ToastComponent }  from './components/toast/toast.component';
import { ToastrModule }    from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [
		ToastComponent
	],
	imports: [
		CommonModule,
		ToastrModule.forRoot({
			toastComponent: ToastComponent,
            tapToDismiss: true,
            progressBar: true,
            disableTimeOut: 'extendedTimeOut',
            closeButton: true,
            timeOut: 1000 * 4,
            autoDismiss: true,
            maxOpened: 5
        }),
        TranslateModule
    ],
    providers: [],
    exports: []
})
export class ToastModule { }
