import { APP_INITIALIZER, NgModule }        from '@angular/core';
import { BrowserModule }                    from '@angular/platform-browser';
import { AppRoutingModule }                 from './app-routing.module';
import { AppComponent }                     from './app.component';
import { AuthModule }                       from "./auth/auth.module";
import { SettingService }                   from "@ngx-dms/common-services";
import { AuthService }                      from "./auth/auth.service";
import { OAuthModule }                      from "angular-oauth2-oidc";
import { HTTP_INTERCEPTORS, HttpClientModule }                from "@angular/common/http";
import { AuthHttpInterceptor }              from "./auth/auth-http.interceptor";
import { IsoCountryService }                from "./services/iso-country.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { from, Observable }                 from "rxjs";
import { LanguageService }                  from "./services/language.service";
import { BsDropdownModule }                 from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule }          from '@angular/platform-browser/animations';
import { ModalModule }                      from 'ngx-bootstrap/modal';
import { ToastModule }                      from './@core/toast/toast.module';
import { OverlayModule }                    from '@angular/cdk/overlay';
import { BsDatepickerModule }               from 'ngx-bootstrap/datepicker';
import { NgxMaskModule }           from 'ngx-mask';
import {ClaimPage} from "./pages/claim/claim.page";

export function appInitialization(setting: SettingService, auth: AuthService, isoCountry: IsoCountryService, language: LanguageService) {
  return async () => {
    await setting.initialize();
    await language.initialize();
    await auth.initialize();
    await isoCountry.initialize();

    return new Promise((resolve) => {
      resolve(true);
    });
  };
}

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    OAuthModule.forRoot(),
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
		ToastModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      defaultLanguage: localStorage.getItem('lang') ? localStorage.getItem('lang')! : 'fr_CA',
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader
      }
    }),
    BsDatepickerModule.forRoot(),
		OverlayModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitialization,
      deps: [SettingService, AuthService, IsoCountryService, LanguageService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    ClaimPage,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
