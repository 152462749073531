import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule }                                                 from '@angular/common';
import * as i18nIsoCountries                                            from "i18n-iso-countries";
import { TranslateService }                                             from "@ngx-translate/core";
import { IPostalAddress }                                               from "../../../interfaces/postal-address.interface";
import { IsoCountryService }                                            from "../../../services/iso-country.service";

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressComponent {

  @Input()
  address?:IPostalAddress;

  constructor(public translate: TranslateService, protected changeRef: ChangeDetectorRef, protected isoCountryService: IsoCountryService) {
   this.translate.onLangChange.subscribe(_ => {
      this.changeRef.detectChanges();
   });
   this.isoCountryService.onLoad.subscribe(_ => {
     this.changeRef.detectChanges();
   });
  }

  get country() {
    if (this.address === undefined)
      return null;

    return i18nIsoCountries.getName(this.address.countryId, this.translate.currentLang.substring(0, 2));
  }

}
