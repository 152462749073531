import { CommonModule }                      from '@angular/common';
import { Component, Input, OnInit }          from '@angular/core';
import { FontAwesomeModule }                 from '@fortawesome/angular-fontawesome';
import { IconDefinition }                    from '@fortawesome/fontawesome-svg-core';
import { faEllipsisVertical, faPlus }        from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BsDropdownModule }                  from 'ngx-bootstrap/dropdown';
import { Observable }                        from 'rxjs';
import { RequiredInput }                     from 'src/app/@core/decorators/RequiredInput';
import { DropdownModule }                    from 'src/app/@core/dropdown/dropdown.module';
import { DmsButtonComponent, IHasIcons }     from "@ngx-dms/ui";

export type ButtonStyle = 'primary' | 'secondary' | 'ellipsis';

export interface IDropdownItem {
	text: Observable<string>;
	icon?: IconDefinition;
	action?: Function;
	route?: string;
	routeNewTab?: boolean;
}

@Component({
  standalone: true,
  selector: 'dms-dropdowns',
  templateUrl: './dropdowns.component.html',
  styleUrls: ['./dropdowns.component.scss'],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    BsDropdownModule,
    DropdownModule,
    DmsButtonComponent,
  ]
})
export class DmsDropdownsComponent implements OnInit, IHasIcons {
  @Input() title?: string;
  @Input() type: ButtonStyle = 'primary';
  @Input() @RequiredInput id!: string;
  @Input() items: IDropdownItem[] = [];

  icons = {
    plus: faPlus,
    ellipsisVertical: faEllipsisVertical
  };

  get dropdownId() {
    return `${this.id}-dropdown`;
  }

  get icon() {
    if (['primary', 'secondary', 'add'].includes(this.type))
      return this.icons.plus;

    return false;
  }

  get text() {
    if (['primary', 'secondary'].includes(this.type))
      return this.translate.stream('BUTTON.ADD');

    return this.translate.stream('ACCESSIBILITY.GENERAL.SHOW_MORE_OPTIONS');
  }

  /*getTarget(item: IDropdownItem) {
    return item.routeTarget ? item.routeTarget : '_self';
  }*/

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }
}
