import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit }                          from '@angular/core';
import { TranslateService }                                      from '@ngx-translate/core';
import { DateTime, Duration }                                    from 'luxon';
import { Toast, ToastPackage, ToastrService }                    from 'ngx-toastr';
import { interval, Subscription }                                from 'rxjs';

@Component({
	selector: '[dms-toast]',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	animations: [
		trigger('flyInOut', [
			state('inactive', style({
				opacity: 0,
			})),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity: 0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity: 1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity: 1,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class ToastComponent extends Toast implements OnInit, OnDestroy {
  createdTime!: DateTime;
  diff!: Duration;
  timeCheckSub!: Subscription;

  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
    private translate: TranslateService
  ) {
    super(toastrService, toastPackage);
  }
  
  get timeAgo() {
    const days = Math.floor(Math.abs(this.diff.as('days')));
    if (days > 0)
      return this.translate.stream("TIME.DAYS_AGO", { value: days });

    const hours = Math.floor(Math.abs(this.diff.as('hours')));
    if (hours > 0)
      return this.translate.stream("TIME.HOURS_AGO", { value: hours });

    const minutes = Math.floor(Math.abs(this.diff.as('minutes')));
    if (minutes > 0)
      return this.translate.stream("TIME.MINUTES_AGO", { value: minutes });

    return this.translate.stream("TIME.RECENTLY_AGO");
  }

  ngOnInit(): void {
    this.initializeTime();
  }

  initializeTime() {
    // if timeout is disable or timeout is greater than a minute
    if (this.options.disableTimeOut !== true && this.originalTimeout < 1000 * 60 * 1)
      return;

    this.createdTime = DateTime.now();
    this.updateTimeDifference();
    this.timeCheckSub = interval(5000)
      .subscribe(this.updateTimeDifference.bind(this));
  }

  updateTimeDifference() {
    this.diff = this.createdTime.diffNow();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.timeCheckSub)
      this.timeCheckSub
        .unsubscribe();
  }

  dismiss() {
    this.remove();
  }
}
