export function RequiredInput(target: Object, key: string | symbol) {
  Object.defineProperty(target, key, {
    get() {
      throw new Error(`Attribute ${String(key)} is required`);
    },
    set(value) {
      Object.defineProperty(target, key, {
        value,
        writable: true,
        configurable: true,
      });
    },
    configurable: true
  });
}