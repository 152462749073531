<address *ngIf="address">
  {{ address.lineOne }} <br>
  <ng-container *ngIf="address.lineTwo">
    {{ address.lineTwo }} <br>
  </ng-container>
  {{ address.cityName }} <br>
  {{ address.administrativeRegion }} <br>
  {{ country }} <br>
  {{ address.postCode }}
</address>
