<ul class="dropdown-menu" >
  <li *ngFor="let item of items">
    <a *ngIf="item.route" (click)="itemClicked(item)">
      <ng-container [ngTemplateOutlet]="itemContent" [ngTemplateOutletContext]="{ item: item }"></ng-container>
    </a>
    <ng-container *ngIf="!item.route" [ngTemplateOutlet]="itemContent" [ngTemplateOutletContext]="{ item: item }">
    </ng-container>
  </li>
</ul>

<ng-template #itemContent let-item="item">
  <dms-button [action]="action(item)">
    <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
    <span class="text">{{ item.text | async }}</span>
  </dms-button>
</ng-template>
