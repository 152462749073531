import { Injectable }               from '@angular/core';
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { SettingService }                    from "@ngx-dms/common-services";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private oauthService: OAuthService, private settings: SettingService) {
  }

  get isLoggedIn() {
    return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
  }

  initialize(): Promise<any> {
    const config: AuthConfig = {
      issuer: this.settings.oauth.issuer,
      strictDiscoveryDocumentValidation: false,
      redirectUri: window.location.origin + '/index.html',
      logoutUrl: this.settings.authRouteUrl('logout'),
      clientId: this.settings.oauth.clientId,
      responseType: 'token',
      requestAccessToken: true,
      scope: 'openid email',
      showDebugInformation: true,
    };

    this.oauthService.configure(config);

    return this.oauthService.loadDiscoveryDocumentAndTryLogin(
      {
        onTokenReceived: (info) => {
          if (info.state) {
            window.location.href = window.atob(info.state);
          }
        },
        disableNonceCheck: true
      }
    ).then(() => {
      this.oauthService.setupAutomaticSilentRefresh();
    });
  }

  login(redirectUri?: string) {
    if (redirectUri === undefined) {
      redirectUri = window.location.origin;
    }

    let state = window.btoa(redirectUri);
    this.oauthService.initLoginFlow(state);
  }

  logout() {
    this.oauthService.logOut({
      client_id: this.settings.oauth.clientId,
      redirect_uri: window.location.origin + '/index.html',
      response_type: 'code'
    });
  }
}
