import { Injectable } from '@angular/core';
import { CacheService, SettingService, DtoDetailWithDescriptionService } from "@ngx-dms/common-services";
import { TranslateService } from "@ngx-translate/core";
import { catchError, map, Observable, tap, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { IResponseDTO } from '../interfaces/dto/response-dto.interface';

export interface ISystemCodeDto {
  systemCodes: {
    [key: string]: {
      [key: string]: string;
    }
  }
}

export interface ISystemCode {
  key: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class SystemCodeDetailService extends DtoDetailWithDescriptionService<ISystemCodeDto, ISystemCode[]> {
  constructor(protected cache: CacheService, private translate: TranslateService, private http: HttpClient, private setting: SettingService) {
    super(translate);
  }

  protected fetchData(id: string): void {
    this.getObserver(id)
      .pipe(
        map<ISystemCodeDto, ISystemCode[]>(data => {
          const systemCodes = data.systemCodes;
          let lang = this.translate.currentLang.substring(0, 2) as keyof typeof systemCodes;
          if (!systemCodes.hasOwnProperty(lang)) return [];
          return Object.keys(systemCodes[lang]).map<ISystemCode>((key) => {
            const name = systemCodes[lang][key];
            return <ISystemCode>{
              key: key,
              name: name
            }
          })
        })
      )
      .subscribe(systemCodes => {
        this._subjects.get(id)?.next(systemCodes);
      });
  }

  protected getCacheKey(id: string): string {
    return `repair-order.${id}.system-codes`;
  }

  protected getRequestObservable(id: string): Observable<ISystemCodeDto> {
    return this.http.get<IResponseDTO<ISystemCodeDto>>(this.setting.routeUrl('v1/repair_order/warranty/claim/manufacturer_info'), {
      params: {
        repairOrderUuid: id
      }
    }).pipe(
      map(result => result.data),
      tap(_ => {
        // we don't want to cache anything for this
        //this.cache.set(this.getCacheKey(id), systemCodes);
      })
    );
  }

  public getManufacturerInfo(id: string, authUuid: string) {
    return this.http.get<IResponseDTO<ISystemCodeDto>>(this.setting.routeUrl('v1/repair_order/warranty/claim/manufacturer_info'), {
      params: {
        repairOrderUuid: id,
        authorizationUuid: authUuid!
      }
    }).pipe(
      // ToDo: Determine if this is a suitable way to catch
      // catchError((error: any) => {
      //   console.log("Status:" + error.status);
      //   console.log("Message:" + error.message);
      //   return throwError(error.statusText);
      // }),
      map(result => {
        const lang = this.translate.currentLang.substring(0, 2);
        let data = result.data;
        let langCodes = data.systemCodes[lang];
        if (!langCodes) {
          return [];
        }
        let keys = Object.keys(langCodes);
        return keys.map(key => {
          return <ISystemCode>{
            key: key,
            name: langCodes[key]
          }
        })
      })
    )
  }
}
