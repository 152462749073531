import { Overlay, OverlayRef }                                  from '@angular/cdk/overlay';
import { ComponentPortal }                                      from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Injector, Input } from '@angular/core';
import { IDropdownItem }                                        from '../../dms-common/components/dropdowns/dropdowns.component';
import { DropdownComponent }                                    from '../dropdown.component';

@Directive({
	selector: '[dmsDropdown]'
})
export class DropdownDirective {
	@Input() items: IDropdownItem[] = [];
	@Input() backdrop: boolean = false;
	ref?: OverlayRef;
	
	constructor(private overlay: Overlay, private injector: Injector, private elementRef: ElementRef) {
  }

  create() {
    /** TODO MATHIAS: adjust and set multiple positions to not overflow outside the viewport */
    /*const positions : ConnectedPosition[] = [
      {
        originX: "start",
        originY: "bottom",
        overlayX: "center",
        overlayY: "top",
        offsetX: 50
      },

    ];*/

    // this.elementRef -

    this.ref = this.overlay.create({
      hasBackdrop: true,
      backdropClass: this.backdrop ? 'cdk-overlay-backdrop-black' : 'cdk-overlay-backdrop-transparent',
      scrollStrategy: this.overlay.scrollStrategies.reposition(), // change scroll strategy
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: "start",
            originY: "top",
            overlayX: "start",
            overlayY: "top"
          },
          /*{
            originX: "start",
            originY: "bottom",
            overlayX: "start",
            overlayY: "bottom",
          }*/

        ])
        //.withPush(true)
    });

    const portal = new ComponentPortal(DropdownComponent, null, this.injector);
    const componentRef = this.ref.attach(portal);
    //componentRef.instance.items = this.items;
    componentRef.setInput('items', this.items);
    componentRef.setInput('destroy', this.close.bind(this));


    this.ref.backdropClick()
      .subscribe(() => {
        this.close();
      });
  }

  private close() {
    this.ref?.dispose();
    this.ref = undefined;
  }

  @HostListener('click')
  onClick() {
    if (!this.ref) {
      this.create();
      return;
    }
  }
}
