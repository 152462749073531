import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayout }        from "./layouts/default-layout/default.layout";
import { AuthGuard }            from "./auth/auth.guard";
import { ClaimLayout } from './layouts/claim-layout/claim.layout';
import {CustomTitleResolver} from "./services/custom-page-title-strategy";

const routes: Routes = [
  {
    path: '',
    component: DefaultLayout,
    title: CustomTitleResolver,
    data: { titleKey: 'APP.PAGE.TITLE.COMPANY_LIST' },
    loadChildren: () => import('./layouts/default-layout/default-layout-routing.module').then(module => module.DefaultLayoutRoutingModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'index.html',
    redirectTo: ''
  },
  {
    path: 'claim',
    component: ClaimLayout,
    title: CustomTitleResolver,
    data: { titleKey: 'APP.PAGE.TITLE.CLAIM_SUBMISSION' },
    loadChildren: () => import('./layouts/claim-layout/claim-layout-routing.module').then(module => module.ClaimLayoutRoutingModule),
    canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [
      RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
