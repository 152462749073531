import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { DropdownDirective }  from './directives/dropdown.directive';
import { OverlayModule }      from '@angular/cdk/overlay';
import { FontAwesomeModule }  from '@fortawesome/angular-fontawesome';
import { DropdownComponent }  from './dropdown.component';
import { DmsButtonComponent } from "@ngx-dms/ui";


@NgModule({
  declarations: [
    DropdownComponent,
    DropdownDirective
  ],
  imports: [
    CommonModule,
    OverlayModule,
    FontAwesomeModule,
    DmsButtonComponent
  ],
  exports: [
    DropdownDirective
  ]
})
export class DropdownModule { }
