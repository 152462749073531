import { Component, HostListener, Input } from '@angular/core';
import { IDropdownItem }                  from 'src/app/@core/dms-common/components/dropdowns/dropdowns.component';

@Component({
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
	@Input() items: IDropdownItem[] = [];
	@Input() destroy!: () => void;
	
	// fix issue where window:click is called at the sametime the component view is initiated, so we need to ignore the first click
  firstClick: boolean = false;

  isOpen = false;

  constructor() { }

  action(item: IDropdownItem) {
    if (!item.action) return undefined;

    return () => {
      if (item.action)
        item.action();
    }
  }

  @HostListener('window:click')
  onWindowClicked() {
    if (this.firstClick === false) {
      this.firstClick = true;
      return;
    }

    this.destroy();
  }

  itemClicked(item: IDropdownItem) {
    if (item.route === undefined) return;
  }
}
