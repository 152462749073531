import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule }                                          from '@angular/common';
import { RouterModule }                                          from "@angular/router";
import { AuthService }                                           from "../../auth/auth.service";
import { TranslateModule, TranslateService }                                      from "@ngx-translate/core";
import { IsoCountryService }                                     from "../../services/iso-country.service";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { BrowserAnimationsModule }          from '@angular/platform-browser/animations';
import { DmsDropdownsComponent, IDropdownItem } from 'src/app/@core/dms-common/components/dropdowns/dropdowns.component';
import { DmsButtonComponent } from '@ngx-dms/ui';
import { DropdownModule } from 'src/app/@core/dropdown/dropdown.module';
import { faCaretDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    BsDropdownModule,
    DmsDropdownsComponent,
    DropdownModule,
    DmsButtonComponent,
    FontAwesomeModule
  ],
  templateUrl: './default.layout.html',
  styleUrls: ['./default.layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultLayout {

  public icons = {
    user: faUser,
    caretDown: faCaretDown
  };

  constructor(private authService: AuthService, public translate: TranslateService, private changeDetectorRef: ChangeDetectorRef, private isoCountryService: IsoCountryService) {
    this.isoCountryService.onLoad.subscribe(_ => this.changeDetectorRef.markForCheck());
  }

  logout() {
    this.authService.logout();
  }

  changeLanguage() {
    let lang = 'fr_CA';
    if (this.translate.currentLang === 'fr_CA') {
      lang = 'en_CA';
    }

    this.translate.use(lang).subscribe(_ => {
      this.changeDetectorRef.markForCheck();
      localStorage.setItem('lang', lang);
    });
  }

  items: IDropdownItem[] = [
      {
        text: this.translate.stream('COMPANY.HEADER.LOGOUT'),
        action: this.logout.bind(this)
      }
    ]

  onHidden(): void {
  }

  onShown(): void {
  }

  isOpenChange(): void {
  }
}
