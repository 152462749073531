import { EventEmitter, Injectable } from '@angular/core';
import * as i18nIsoCountries        from 'i18n-iso-countries';
import { BehaviorSubject, from, map, Observable, of, tap } from "rxjs";
import { HttpClient }                                      from "@angular/common/http";
import { TranslateService }           from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class IsoCountryService {
  $initialized = new BehaviorSubject<boolean>(false);
  onLoad = new EventEmitter<string>();

  get initialized(): boolean {
    return this.$initialized.value;
  }

  constructor(private http: HttpClient, private translate: TranslateService) {
  }

  initialize(): Promise<any> {
    return new Promise<void>(
      (resolve) => {
        this.loadLanguage(this.translate.currentLang.substring(0,2)).subscribe();
        this.translate.onLangChange.subscribe(e => {
          this.loadLanguage(e.lang.substring(0,2)).subscribe();
        })
        this.$initialized.next(true);
        this.$initialized = new BehaviorSubject<boolean>(false);
        resolve();
      });
  }

  private loadLanguage(lang: string): Observable<boolean> {
    if (i18nIsoCountries.langs().includes(lang)) {
      return of(true);
    }

    return from(import(`../../assets/i18n-iso-countries/langs/${lang}.json`))
      .pipe(
        tap(
          langData => {
            i18nIsoCountries.registerLocale(langData);
            this.onLoad.next(lang);
          }
        ),
        map(
          x => true
        ));
  }
}
