import { CommandHandlerBase, SettingService } from "@ngx-dms/common-services";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

export interface ICreateWarrantyClaimCommand {
  jobId: string;
  authorizationUuid: string;    // ToDo: I added this to add the authorizationUuid to the "Submit" button
  causalPartId: number;
  systemCode: string;
  symptomDescription: string;
  remedyDescription?: string;
  defectDescription?: string;
}

export class CreateWarrantyClaimCommandHandler extends CommandHandlerBase<ICreateWarrantyClaimCommand>
{
  constructor(private http: HttpClient, private setting: SettingService) {
    super();
  }

  execute(command: ICreateWarrantyClaimCommand): Observable<any> {
    const copy: any = {...command};
    delete copy.jobId;

    return this.http.post(this.setting.routeUrl('v1/repair_order/warranty/claim'), {
      manufacturer: "brp", // hardcoded according to the backend devs so don't ask me questions >:],
                           // ToDo: This value should be taken dynamically based on the manufacturer of the vehicle
                           // on the repairOrder - The part that would currently be hard-coded is the list of supported
                           // warranty claim integrations. - A. Ravary
      ...copy
    }, {
      params: {
        repairOrderJobUuid: command.jobId,
        authorizationUuid: command.authorizationUuid, // ToDo: I added this to add the authorizationUuid to the "Submit" button
        // ToDo: Looks like we may need to send 'authorizationUuid' - how do we get it from this context?
      }
    });
  }
}
