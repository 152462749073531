<div class="container" *ngIf="repairOrderDetails; else loading">
  <dms-page-title>{{ 'CLAIM.PAGE_TITLE' | translate }}</dms-page-title>

  <div class="row">
    <!-- Repair Order Confirmation -->
    <div class="col-12 col-md-6 col-xl-4 d-flex flex-column">
      <dms-section-title>{{ 'CLAIM.REPAIR_ORDER_INFORMATIONS' | translate }}</dms-section-title>
      <dms-card>
        <ng-container *dmsCardContent>
          <dl>
            <div class="row dl-group">
              <dt class="col-sm-6">
                <strong>{{ 'CLAIM.ORDER_#' | translate }}</strong>
              </dt>
              <dd class="col-sm-6">
                <strong>{{ repairOrderDetails.order.orderNumber }}</strong>
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'CLAIM.DATE_OPENED' | translate }}
              </dt>
              <dd class="col-sm-6">
                {{ repairOrderDetails.order.openingDate | date:'YYYY-MM-dd'  }}
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'CLAIM.DATE_CLOSED' | translate }}
              </dt>
              <dd class="col-sm-6">
                {{ repairOrderDetails.order.closingDate | date:'YYYY-MM-dd'  }}
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'CLAIM.ODOMETER_IN' | translate }}
              </dt>
              <dd class="col-sm-6">
                {{ repairOrderDetails.order.odometer }} hrs
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'CLAIM.JOBS' | translate }}
              </dt>
              <dd class="col-sm-6">
                {{ repairOrderDetails.order.jobs.length }}
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'CLAIM.ADVISOR' | translate }}
              </dt>
              <dd class="col-sm-6">
                {{ repairOrderDetails.order.advisor }}
              </dd>
            </div>
          </dl>
        </ng-container>
      </dms-card>
    </div>

    <!-- Customer -->
    <div class="col-12 col-md-6 col-xl-4 d-flex flex-column">
      <dms-section-title>{{ 'CLAIM.CUSTOMER' | translate }}</dms-section-title>
      <dms-card>
        <ng-container *dmsCardContent>
          <dl>
            <div class="row dl-group">
              <dt class="col-sm-6">
                <strong>{{ 'GENERAL.NAME' | translate }}</strong>
              </dt>
              <dd class="col-sm-6">
                <strong>{{ repairOrderDetails.customer.name }}</strong>
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'GENERAL.PHONE' | translate }}
              </dt>
              <dd class="col-sm-6">
                <div *ngFor="let phone of repairOrderDetails.customer.phone">
                {{ phone | mask: '(000) 000-0000'}}
              </div>
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'GENERAL.POSTAL_ADDRESS' | translate }}
              </dt>
              <dd class="col-sm-6">
                <ng-container *ngFor="let address of repairOrderDetails.customer.addresses; first as isFirst">
                  <app-address [address]="address" *ngIf="isFirst"></app-address>
                </ng-container>
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'GENERAL.EMAIL' | translate }}
              </dt>
              <dd class="col-sm-6">
                <ng-container *ngFor="let email of repairOrderDetails.customer.emails; first as isFirst">
                  <span *ngIf="isFirst">{{ email }}</span>
                </ng-container>
              </dd>
            </div>
          </dl>
        </ng-container>
      </dms-card>
    </div>

    <!-- Vehicle -->
    <div class="col-12 col-md-6 col-xl-4 d-flex flex-column">
      <dms-section-title>{{ 'GENERAL.VEHICLE' | translate }}</dms-section-title>
      <dms-card>
        <ng-container *dmsCardContent>
          <dl>
            <div class="row dl-group">
              <dt class="col-sm-6">
                <strong>{{ 'GENERAL.BRAND' | translate }}</strong>
              </dt>
              <dd class="col-sm-6">
                <strong>{{ repairOrderDetails.vehicle.brand }}</strong>
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'VEHICLE.VIN' | translate }}
              </dt>
              <dd class="col-sm-6">
                {{ repairOrderDetails.vehicle.VIN }}
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'VEHICLE.MODEL' | translate }}
              </dt>
              <dd class="col-sm-6">
                {{ repairOrderDetails.vehicle.model }}
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'VEHICLE.COLOR' | translate }}
              </dt>
              <dd class="col-sm-6">
                {{ repairOrderDetails.vehicle.exteriorColor }} / {{ repairOrderDetails.vehicle.interiorColor}}
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'GENERAL.DATE_DELIVERED' | translate }}
              </dt>
              <dd class="col-sm-6">
                {{ repairOrderDetails.vehicle.deliveredDate | date:'YYYY-MM-dd' }}
              </dd>
            </div>
            <div class="row dl-group">
              <dt class="col-sm-6">
                {{ 'CLAIM.WARRANTY_EXPIRATION' | translate }}
              </dt>
              <dd class="col-sm-6">
                <!-- <ng-container *ngIf="repairOrderDetails.vehicle.warrantyExpirationDate; else notAvailable"> -->
                <ng-container *ngIf="repairOrderDetails.vehicle.warrantyExpirationDate">
                  {{ repairOrderDetails.vehicle.warrantyExpirationDate | date:'YYYY-MM-dd' }}
                </ng-container>
                <br />
                <!-- TODO: NOT RETURNED BY API YET... -->
              </dd>
            </div>
          </dl>
        </ng-container>
      </dms-card>
    </div>
  </div>


  <dms-card class="job-selector" *ngIf="!isManufacturerSupported()">
    <ng-container *dmsCardContent>
      {{ 'CLAIM.NOT_A_SUPPORTED_MAKE' | translate}}
    </ng-container>
  </dms-card>

<div *ngIf="isManufacturerSupported()">
  <dms-card class="job-selector">
    <ng-container *dmsCardContent>
      <dms-section-title>
        {{ 'CLAIM.SELECT_JOB_MSG' | translate }}
      </dms-section-title>
      <ng-select [items]="repairOrderDetails.order.jobs" bindLabel="title"
                 [placeholder]="'CLAIM.SELECT_JOB_MSG' | translate" [(ngModel)]="selectedJob" (change)="jobSelected($event)"></ng-select>
    </ng-container>
  </dms-card>

  <ng-container *ngIf="selectedJob">
    <dms-card class="claim-details pb-0">
      <ng-container *dmsCardContent>
        <dms-section-title>
          {{ 'CLAIM.CLAIM_#' | translate:{ number: jobWarrantyNumber } }}
          <ng-container *dmsSectionTitleAction>
            <div class="status" [ngClass]="jobWarrantyStatus | lowercase"> {{ 'WARRANTY.STATUS.' + jobWarrantyStatus | translate }} </div>
            <div class="refresh" (click)="refresh()"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
              width="30" height="30"
              viewBox="0 0 30 30">
              <path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
              </svg>
            </div>
          </ng-container>
        </dms-section-title>
        <tabset>
          <tab id="parts" (selectTab)="onTabChange($event)"><ng-template tabHeading>{{ 'CLAIM.PARTS' | translate }}</ng-template></tab>
          <tab id="labour" (selectTab)="onTabChange($event)"><ng-template tabHeading>{{ 'CLAIM.LABOUR' | translate }}</ng-template></tab>
          <tab id="comments" (selectTab)="onTabChange($event)"><ng-template tabHeading>{{ 'CLAIM.COMMENTS' | translate }}</ng-template></tab>
        </tabset>
      </ng-container>
    </dms-card>

    <dms-card class="pe-0 ps-0">
      <ng-container *dmsCardContent>
        <div class="tab-parts" [hidden]="currentTab != 'parts'">
          <ag-grid-angular
            class="ag-theme-balham"
            [rowData]="selectedJob.parts"
            [gridOptions]="partGridOptions"
            [columnDefs]="partsColumnDefs"
            [defaultColDef]="defaultColDef">
          </ag-grid-angular>
        </div>
        <div class="tab-labour" [hidden]="currentTab != 'labour'">
          <ag-grid-angular
            class="ag-theme-balham"
            [rowData]="selectedJob.labors"
            [gridOptions]="labourGridOptions"
            [columnDefs]="labourColumnDefs"
            [defaultColDef]="defaultColDef">
          </ag-grid-angular>
        </div>
        <div class="tab-comments" [hidden]="currentTab != 'comments'">
          <h3>{{ 'CLAIM.DESCRIPTION' | translate }}</h3>
          <p [innerHTML]="selectedJob.description"></p>
          <h3>{{ 'CLAIM.TECHNICAL_NOTES' | translate }}</h3>
          <p [innerHTML]="selectedJob.technicianNotes"></p>
          <h3>{{ 'CLAIM.CLOSING_NOTES' | translate }}</h3>
          <p [innerHTML]="selectedJob.closingNotes"></p>
        </div>
      </ng-container>
    </dms-card>

    <dms-card>
      <ng-container *dmsCardContent>
        <div *ngIf="selectedJob.warranties.length == 0">
          {{ 'CLAIM.NO_WARRANTY_CLAIMS' | translate}}
        </div>

        <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="selectedJob.warranties.length!==0" >
          <div class="row">
            <div class="col-12 col-md-6 mb-4">
              <!-- Causal part -->
              <label class="form-label" for="causal_part">{{ 'CLAIM.CAUSAL_PART' | translate }}</label>
              <ng-select id="causal_part" [items]="selectedJob.parts" bindLabel="part.label" bindValue="part.id"
                         [placeholder]="'CLAIM.SELECT_CAUSAL_PART_MSG' | translate" formControlName="causal_part"
                         [readonly]="jobWarrantyStatus + '' != 'UNCLAIMED'">
                <ng-template ng-label-tmp let-item="item">
                  {{item.part?.label}}
                </ng-template>
              </ng-select>
              <div class="invalid-feedback" [hidden]="!hasError(form.controls.causal_part)" [innerHtml]="errorMessage(form.controls.causal_part) | async"></div>
            </div>
            <div class="col-12 col-md-6 mb-4">
              <!-- Claim code -->
              <label class="form-label" for="claim_code">{{ 'CLAIM.CLAIM_CODE' | translate }}</label>
              <ng-select id="claim_code" [items]="systemCodes$ | async" bindLabel="name" bindValue="key"
                         [placeholder]="'CLAIM.SELECT_CLAIM_CODE_MSG' | translate" formControlName="system_code"
                         [readonly]="jobWarrantyStatus + '' != 'UNCLAIMED'"></ng-select>
              <div class="invalid-feedback" [hidden]="!hasError(form.controls.system_code)" [innerHtml]="errorMessage(form.controls.system_code) | async"></div>
            </div>
            <div class="col-12 col-md-6 mb-4">
              <!-- Symptoms description -->
              <label class="form-label" for="symptoms_description">{{ 'CLAIM.SYMPTOMS_DESCRIPTION' | translate }}</label>
              <textarea id="symptoms_description" placeholder="" formControlName="symptom_description" [readonly]="jobWarrantyStatus + '' != 'UNCLAIMED'"></textarea>
              <div class="invalid-feedback" [hidden]="!hasError(form.controls.symptom_description)" [innerHtml]="errorMessage(form.controls.symptom_description) | async"></div>
            </div>
            <div class="col-12 col-md-6 mb-4">
              <!-- Remedy description -->
              <label class="form-label" for="remedy_description">{{ 'CLAIM.REMEDY_DESCRIPTION' | translate }}</label>
              <textarea id="remedy_description" placeholder="" formControlName="remedy_description" [readonly]="jobWarrantyStatus + '' != 'UNCLAIMED'"></textarea>
              <div class="invalid-feedback" [hidden]="!hasError(form.controls.remedy_description)" [innerHtml]="errorMessage(form.controls.remedy_description) | async"></div>
            </div>
            <div class="col-12 col-md-6 mb-4">
              <!-- Detect description -->
              <label class="form-label" for="defect_description">{{ 'CLAIM.DEFECT_DESCRIPTION' | translate }}</label>
              <textarea id="defect_description" placeholder="" formControlName="defect_description" [readonly]="jobWarrantyStatus + '' != 'UNCLAIMED'"></textarea>
              <div class="invalid-feedback" [hidden]="!hasError(form.controls.defect_description)" [innerHtml]="errorMessage(form.controls.defect_description) | async"></div>
            </div>
          </div>
          <div class="submit-actions">
            <div class="submit-actions__item">
              <dms-button title="Reset Form" type="cancel" (click)="form.reset()" *ngIf="jobWarrantyStatus + '' == 'UNCLAIMED'">
                {{ 'GENERAL.RESET_FORM' | translate }}
              </dms-button>
            </div>
            <div class="group-actions__item">
              <dms-button title="Submit" type="submit" *ngIf="jobWarrantyStatus + '' == 'UNCLAIMED'">
                {{ 'GENERAL.SUBMIT' | translate }}
              </dms-button>
            </div>
          </div>
        </form>
      </ng-container>
    </dms-card>
  </ng-container>
</div>
</div>

<ng-template #loading>
  <div class="loading">
    {{ 'CLAIM.LOADING_MESSAGE' | translate }}
  </div>
</ng-template>

<ng-template #notAvailable>
  <strong>{{ 'GENERAL.NOT_AVAILABLE_ABBREVIATION' | translate }}</strong>
</ng-template>

<ng-template> <!-- UNUSED TEMPLATE ONLY FOR GENERATING TRANSLATIONS -->
  {{ 'FORM.ERRORS.REQUIRED' | translate }}

  {{ 'WARRANTY.STATUS.UNKNOWN' | translate }}
  {{ 'WARRANTY.STATUS.UNCLAIMED' | translate }}
  {{ 'WARRANTY.STATUS.CLAIMED' | translate }}
</ng-template>
