import { Injectable }                                           from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, EMPTY, Observable, throwError }            from 'rxjs';
import { OAuthService }                                         from 'angular-oauth2-oidc';
import { AuthService }                                          from "./auth.service";
import { SettingService }                                       from '@ngx-dms/common-services';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private oauthService: OAuthService, private authService: AuthService,
              private settings: SettingService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.settings.initialized === false)
      return next.handle(request);

    if (request.url.startsWith(this.settings.endpoint) === false) {
      return next.handle(request);
    }
    
    if (request.url.endsWith('/v1/repair_order/warranty_claim')
      || request.url.endsWith('/v1/repair_order/warranty/claim')
      || request.url.endsWith('/v1/repair_order/warranty/claim/manufacturer_info')) {
        return next.handle(request);
      }

    if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
      this.oauthService.tryLogin().then(doc => {
        if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
          this.oauthService.initLoginFlow();
        }
      });

      return EMPTY;
    }

    let token = this.oauthService.getIdToken();

    let headers = request.headers
      .set('Authorization', 'Bearer ' + token);

    request = request.clone({headers});

    return next.handle(request)
      .pipe(
        catchError(err => {
          return throwError(() => err);
        })
      );
  }
}
