import { OAuthModule, OAuthStorage }           from "angular-oauth2-oidc";
import { CommonModule }                        from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthGuard }                           from "./auth.guard";
import { AuthHttpInterceptor }                 from "./auth-http.interceptor";
import { NgModule }                            from "@angular/core";

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    AuthHttpInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {provide: OAuthStorage, useFactory: storageFactory}
  ],
  exports: []
})
export class AuthModule {
}
